import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import TermlyPolicy from "../components/TermlyPolicy";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Seo title="Privacy Policy" />

      <div className="container mb-5">
        <div className="col-12">
          <article className="row flex-column-reverse flex-md-row">
            <div className="col-md-8 offset-md-2">
              <TermlyPolicy policyId="2af691c2-84dd-4cd3-931c-7bc9d30fae5c" />
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
